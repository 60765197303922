import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import metadata from '../../metadatas/metadata';
import { getAssuranceProducts, getBanner, getRecommendProducts } from '@/api/public';
import BannerCarousel from '@/components/Common/Banner/BannerCarousel';
import TopBanner from '@/components/Common/Banner/TopBanner';
import HomePopup from '@/components/Common/Popup/HomePopup';
import Footer from '@/components/Footer';
import AssuranceProduts from '@/components/Home/AssuranceProducts';
import RecommendProducts from '@/components/Home/RecommendProducts';
import SubCards from '@/components/Home/SubCards';
import {
  BellIcon,
  CalendarCheckColorIcon,
  DeliveryTruckIcon,
  IncreasingTrendGraphColorIcon,
  JobBoldColorIcon,
  LicensePlateBoldColorIcon,
  SearchOutlineBoldIcon,
} from '@/components/Icon';
import SvgSearchQuestionBoldIcon from '@/components/Icon/SearchQuestionBoldIcon';
import OnBoading from '@/components/Login/OnBoading';
import { HOME, HOME_POP_UP } from '@/const/banner';
import { colors } from '@/const/colors';
import { PRODUCT_PURCHASE_INQUIRY_PATH, REPRESENTATIVE_NUMBER } from '@/const/common';
import useAuthRedirect from '@/hooks/useAuthRedirect';
import { PageMetas } from '@/metadatas/metadatas';
import { isExistNotReadNotificationAtom } from '@/store/notification';

const businessInfo = [
  { title: '상호', content: '주식회사 직트럭' },
  { title: '대표', content: '양요한' },
  { title: '주소', content: '경기도 평택시 월곡길 9-9' },
  { title: '대표 전화', content: REPRESENTATIVE_NUMBER },
  { title: '사업자 번호', content: '721-87-01975' },
  { title: '통신판매업 신고번호', content: '제 2023-경기평택-1174호' },
];

const Home = () => {
  const [isExistNotReadNotification] = useAtom(isExistNotReadNotificationAtom);

  const userAgent = navigator.userAgent;

  const isNaverInApp = /NAVER/.test(userAgent);
  const isKakaoInApp = /KAKAOTALK/.test(userAgent);

  const isAndroidWeb =
    /Android/i.test(userAgent) && /WebKit/i.test(userAgent) && (!/wv/i.test(userAgent) || isNaverInApp || isKakaoInApp);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const deviceToken = searchParams.get('deviceToken');

  const authRedirect = useAuthRedirect();

  const [isShowOnBoading, setIsShowOnBoading] = useState<boolean>();
  const [isShow, setIsShow] = useState<boolean>(true);
  const [isShowTopBanner, setIsShowTopBanner] = useState(false);

  const subFunctionCards: ButtonCard[] = [
    {
      title: '시세 검색',
      path: '/price-trend',
      icon: (
        <IncreasingTrendGraphColorIcon color={colors.gray[6]} width={38} height={38}></IncreasingTrendGraphColorIcon>
      ),
    },
    {
      title: '번호판 거래',
      path: '/license',
      icon: <LicensePlateBoldColorIcon color={colors.gray[6]} width={38} height={38}></LicensePlateBoldColorIcon>,
    },
    {
      title: '운행일지',
      path: '/drive',
      icon: <CalendarCheckColorIcon color={colors.gray[6]} width={38} height={38}></CalendarCheckColorIcon>,
    },
    {
      title: '일자리 구하기',
      path: '/job',
      icon: <JobBoldColorIcon color={colors.gray[6]} width={38} height={38}></JobBoldColorIcon>,
    },
  ];

  const LOCALSTORAGE_KEY = 'homePopupTime';

  const getRandomPopupImage = (popupImages: Banner[]): Banner => {
    const length = popupImages.length;
    const randomIndex = Math.floor(Math.random() * length);
    return popupImages[randomIndex];
  };

  useEffect(() => {
    if (isAndroidWeb) {
      setIsShowTopBanner(true);
    }

    if (deviceToken) {
      localStorage.setItem('deviceToken', deviceToken);
    }

    setIsShowOnBoading(localStorage.getItem('isFirst') !== 'false');

    checkPopupClose() ? setIsShow(false) : setIsShow(true);
  }, []);

  const queryOptions = {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  };

  const {
    data: bannersData = [],
    isLoading: bannersLoading,
    isError: bannersError,
  } = useQuery('banners', getBanner, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60,
    cacheTime: 1000 * 60 * 24,
  });

  const filteredBanners = bannersData.filter((banner: Banner) => banner.bannerLocation.code === HOME);
  const filterPopup = bannersData.filter((banner: Banner) => banner.bannerLocation.code === HOME_POP_UP);
  const randomPopupImage = filterPopup.length > 0 ? getRandomPopupImage(filterPopup) : null;

  const {
    data: assuranceProducts = [],
    isLoading: assuranceProductsLoading,
    isError: assuranceProductsError,
  } = useQuery('get-assurance-products', getAssuranceProducts, queryOptions);

  const {
    data: recommendProducts = [],
    isLoading: recommendProductsLoading,
    isError: recommendProductsError,
  } = useQuery('get-recommend-products', getRecommendProducts, queryOptions);

  const closePopup = (expireDays: number) => {
    let expire = new Date();
    expire.setTime(expire.getTime() + expireDays * 24 * 60 * 60 * 1000);
    localStorage.setItem(LOCALSTORAGE_KEY, expire.getTime().toString());
  };

  const checkPopupClose = () => {
    const expireDay = localStorage.getItem(LOCALSTORAGE_KEY);
    let today = new Date();

    return today.getTime() <= Number(expireDay) || sessionStorage.getItem('isHomePopup') === 'false';
  };

  const onClickCloseToday = () => {
    closePopup(1);
    setIsShow(false);
  };

  const onClickClose = () => {
    setIsShow(false);
  };

  useEffect(() => {
    sessionStorage.setItem('isHomePopup', `${isShow}`);
  }, [isShow]);

  const onClickGoHome = () => {
    setIsShowOnBoading(() => false);
  };

  const onClickPurchaseInquiry = () => {
    if (!authRedirect(PRODUCT_PURCHASE_INQUIRY_PATH)) {
      return;
    }
    navigate('/products/purchase');
  };

  return (
    <>
      <PageMetas {...metadata.home}></PageMetas>
      {isShowOnBoading ? (
        <OnBoading onClickGoHome={onClickGoHome}></OnBoading>
      ) : (
        <div className={`w-full ${isShowTopBanner ? 'pt-[70px]' : ''}`}>
          <div className="pb-20">
            <div className="flex flex-col p-4">
              <div className="flex justify-between">
                <img
                  loading="lazy"
                  src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/logo_primary.png"
                  alt="직트럭 로고"
                  className="mb-4"
                  width={50}
                  height={24}
                ></img>

                <div className="flex gap-2">
                  <Link to="/notification/#activity" aria-label="알림 확인" role="link" className="cursor-pointer flex">
                    <div className="relative w-[26px] h-[26px] flex justify-center items-center">
                      <BellIcon color={colors.gray[8]} width={26} height={26}></BellIcon>
                      {isExistNotReadNotification && (
                        <div className="absolute top-0 right-0 w-1 h-1 bg-red rounded"></div>
                      )}
                    </div>
                  </Link>
                </div>
              </div>

              <BannerCarousel banners={filteredBanners}></BannerCarousel>

              <div className="mt-6 mb-4">
                <div className="flex sm280:flex-col gap-[12px]">
                  <Link
                    to="/sell-car"
                    className="flex-1 flex flex-col justify-between bg-blue-1 px-[18px] py-5 rounded-[10px] w-full"
                  >
                    <div className="flex justify-between">
                      <span className="text-gray-8 font-bold text-[20px] leading-[24px] break-keep">
                        내 차 판매하기
                      </span>
                      <div>
                        <DeliveryTruckIcon color={colors.blue[2]} width={40} height={40}></DeliveryTruckIcon>
                      </div>
                    </div>
                    <span className="text-gray-7 font-regular text-[14px] leading-[18px] mt-[10px]">
                      1분만에 차량을 등록
                      <br className="block sm550:hidden sm280:hidden" />
                      하고 판매해보세요.
                    </span>
                  </Link>
                  <Link
                    to="/products"
                    className="flex-1 flex flex-col justify-between bg-blue-1 px-[18px] py-5 rounded-[10px] w-full"
                  >
                    <div className="flex justify-between">
                      <span className="text-gray-8 font-bold text-[20px] leading-[24px]">매물 보기</span>
                      <div>
                        <SearchOutlineBoldIcon color={colors.blue[2]} width={24} height={24}></SearchOutlineBoldIcon>
                      </div>
                    </div>
                    <span className="text-gray-7 font-regular text-[14px] leading-[18px] mt-[10px]">
                      내가 찾던 차량을 <br className="block sm550:hidden sm280:hidden" />
                      빠르게 검색해보세요.
                    </span>
                  </Link>
                </div>
              </div>
              <SubCards cards={subFunctionCards}></SubCards>
              <div className="mt-4" onClick={onClickPurchaseInquiry}>
                <div className="flex flex-row items-center justify-center w-full bg-gray-1 p-4 rounded-[12px]">
                  <SvgSearchQuestionBoldIcon color={colors.gray[7]} width={40} height={40}></SvgSearchQuestionBoldIcon>

                  <div className="flex flex-col flex-1 ml-3">
                    <span className="font-semibold text-lg text-gray-8">구매 문의 남기기</span>
                    <span className="text-[14px] text-gray-7 sm280:text-[13px] break-keep">
                      원하는 차량이 없다면 <br className="hidden sm280:block" />
                      문의를 남겨주세요
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <AssuranceProduts products={assuranceProducts}></AssuranceProduts>

            <RecommendProducts products={recommendProducts}></RecommendProducts>

            <div className="py-5 px-4 bg-gray-1 w-full">
              <h3 className="text-gray-7 text-[13px] mb-2">직트럭 사업자 정보</h3>
              <div className="text-gray-6 text-[10px] mb-3">
                <dl>
                  {businessInfo.map((item, index) => (
                    <div key={index} className="table-row">
                      <dt className="table-cell min-w-[140px]">{item.title}</dt>
                      <dd>{item.content}</dd>
                    </div>
                  ))}
                </dl>
              </div>
              <div className="text-gray-6 text-[10px]">
                <p>ⓒ 2020 주식회사 직트럭 All Rights Reserved</p>
                <p>
                  주식회사 직트럭은 통신판매중개자로서 통신판매의 당사자가 아니며, 상품 및 거래정보, 거래에 대해 책임을
                  지지않습니다.
                </p>
              </div>
            </div>
          </div>

          <Footer isMain></Footer>
          {randomPopupImage && (
            <HomePopup
              isShow={isShow}
              image={randomPopupImage}
              textRightBtn="닫기"
              onClickRightBtn={onClickClose}
              textLeftBtn={'오늘 하루 보지 않기'}
              onClickLeftBtn={onClickCloseToday}
            ></HomePopup>
          )}

          <TopBanner isShow={isShowTopBanner}></TopBanner>
        </div>
      )}
    </>
  );
};

export default Home;
